import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings.js";
import { Link } from "../../utils/general.js";

const Container = tw.div`relative w-full`;
const TwoColumn = tw.div`flex flex-col justify-between max-w-screen-xl mx-auto py-8 md:py-16 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`w-full flex-shrink-0 relative`;

const PrimaryButton = styled(Link)`
${tw` px-8 py-3 font-bold rounded bg-primary-900 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 rounded-full bg-secondary-900 hover:no-underline cursor-pointer`}
`
const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const TextContent = tw.div`w-full lg:py-4 text-center`;

const Subheading = tw(SubheadingBase)`text-center text-black font-normal`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-center text-3xl sm:text-4xl lg:text-5xl text-center leading-tight text-black`;
const Description = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black font-normal`;

// const PrimaryButton = styled(PrimaryButtonBase)(props => [
//   tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
//   props.buttonRounded && tw`rounded-full`
// ]);


export default ({
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  textOnLeft = false,
  data,
  page
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  let finalData= data?.schema_json;  
  console.log('Text With Image', finalData)
  return (
    <Container>
      <TwoColumn>
          <TextContent>
            <Subheading>{finalData.subHeading}</Subheading>
            <Heading>{finalData.heading}</Heading>
            <Description>{finalData.description}</Description>
          </TextContent>
        <ImageColumn>
          <Image css={imageCss} src={finalData.img_src} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
        </ImageColumn>
        <PrimaryButton 
            href={finalData.open_new_tab ? finalData.primary_button_url : null}
            to={finalData.open_new_tab ? null : finalData.primary_button_url}
            target="_blank"
            >
            {finalData.primary_button_text}
        </PrimaryButton>
      </TwoColumn>
      
    </Container>
  );
};
